/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DisciplineDataContentDTO,
    DisciplineDataContentDTOFromJSON,
    DisciplineDataContentDTOFromJSONTyped,
    DisciplineDataContentDTOToJSON,
} from './DisciplineDataContentDTO';

/**
 * 
 * @export
 * @interface RoomDataDTO
 */
export interface RoomDataDTO {
    /**
     * 
     * @type {string}
     * @memberof RoomDataDTO
     */
    roomNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoomDataDTO
     */
    roomName?: string | null;
    /**
     * 
     * @type {Array<DisciplineDataContentDTO>}
     * @memberof RoomDataDTO
     */
    disciplines?: Array<DisciplineDataContentDTO> | null;
}

export function RoomDataDTOFromJSON(json: any): RoomDataDTO {
    return RoomDataDTOFromJSONTyped(json, false);
}

export function RoomDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomDataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomNumber': !exists(json, 'roomNumber') ? undefined : json['roomNumber'],
        'roomName': !exists(json, 'roomName') ? undefined : json['roomName'],
        'disciplines': !exists(json, 'disciplines') ? undefined : (json['disciplines'] === null ? null : (json['disciplines'] as Array<any>).map(DisciplineDataContentDTOFromJSON)),
    };
}

export function RoomDataDTOToJSON(value?: RoomDataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roomNumber': value.roomNumber,
        'roomName': value.roomName,
        'disciplines': value.disciplines === undefined ? undefined : (value.disciplines === null ? null : (value.disciplines as Array<any>).map(DisciplineDataContentDTOToJSON)),
    };
}

