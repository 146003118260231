/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EquipmentDisciplineDataDTO,
    EquipmentDisciplineDataDTOFromJSON,
    EquipmentDisciplineDataDTOFromJSONTyped,
    EquipmentDisciplineDataDTOToJSON,
} from './EquipmentDisciplineDataDTO';

/**
 * 
 * @export
 * @interface EquipmentRoomDataDTO
 */
export interface EquipmentRoomDataDTO {
    /**
     * 
     * @type {string}
     * @memberof EquipmentRoomDataDTO
     */
    roomNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentRoomDataDTO
     */
    roomName?: string | null;
    /**
     * 
     * @type {Array<EquipmentDisciplineDataDTO>}
     * @memberof EquipmentRoomDataDTO
     */
    disciplines?: Array<EquipmentDisciplineDataDTO> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EquipmentRoomDataDTO
     */
    laboratoryEquipment?: Array<string> | null;
}

export function EquipmentRoomDataDTOFromJSON(json: any): EquipmentRoomDataDTO {
    return EquipmentRoomDataDTOFromJSONTyped(json, false);
}

export function EquipmentRoomDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentRoomDataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomNumber': !exists(json, 'roomNumber') ? undefined : json['roomNumber'],
        'roomName': !exists(json, 'roomName') ? undefined : json['roomName'],
        'disciplines': !exists(json, 'disciplines') ? undefined : (json['disciplines'] === null ? null : (json['disciplines'] as Array<any>).map(EquipmentDisciplineDataDTOFromJSON)),
        'laboratoryEquipment': !exists(json, 'laboratoryEquipment') ? undefined : json['laboratoryEquipment'],
    };
}

export function EquipmentRoomDataDTOToJSON(value?: EquipmentRoomDataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roomNumber': value.roomNumber,
        'roomName': value.roomName,
        'disciplines': value.disciplines === undefined ? undefined : (value.disciplines === null ? null : (value.disciplines as Array<any>).map(EquipmentDisciplineDataDTOToJSON)),
        'laboratoryEquipment': value.laboratoryEquipment,
    };
}

