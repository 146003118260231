import { AssignLaboratoresDTO, SemesterTypeEnum, StudyYearEnum, AddSubjectFileDTO, SubjectApi, SubjectFileDTO, AddSubjectDTO } from "../Api";
import { SubjectDTO } from "../Api/models/SubjectDTO";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new SubjectApi(getAuthConfiguration());

export const getSubject = (id: string) => {
    if (!id) {
        return {} as SubjectFileDTO;
    }
    return ApiFactory().apiSubjectGetSubjectByIdIdGet({ id });
}

export const getShortSubject = (id: string) => {
    if (!id) {
        return {} as SubjectDTO;
    }
    return ApiFactory().apiSubjectGetSubjectPrimDataByIdIdGet({ id });
}

export const getSubjects  = (namePattern?: string, schoolYear?: number, planYear?: number, semester?: SemesterTypeEnum, studyYear?: StudyYearEnum, studyProgramId?: string, page: number = 1, pageSize: number = 10) => {
    return ApiFactory().apiSubjectGetAllSubjectsGet({ page, pageSize, namePattern, schoolYear, semester, studyYear, studyProgramId, planYear });
}

export const getShortSubjects  = (namePattern?: string, schoolYear?: number, planYear?: number, semester?: SemesterTypeEnum, studyYear?: StudyYearEnum, studyProgramId?: string, page: number = 1, pageSize: number = 10) => {
    return ApiFactory().apiSubjectGetAllSubjectsPrimDataGet({ page, pageSize, namePattern, schoolYear, semester, studyYear, studyProgramId, planYear });
}

export const addSubject = (addSubjectFileDTO: AddSubjectFileDTO) => {
    return ApiFactory().apiSubjectAddSubjectPost({ addSubjectFileDTO });
}

export const updateSubject = (id: string, addSubjectFileDTO: AddSubjectFileDTO) => { 
    return ApiFactory().apiSubjectUpdateSubjectIdPut({ id, addSubjectFileDTO });
}

export const addShortSubject = (addSubjectDTO: AddSubjectDTO) => {
    return ApiFactory().apiSubjectAddPrimarySubjectPost({ addSubjectDTO });
}

export const updateShortSubject = (id: string, addSubjectDTO: AddSubjectDTO) => { 
    return ApiFactory().apiSubjectUpdatePrimarySubjectIdPut({ id, addSubjectDTO });
}

export const deleteSubject = (id: string) => {
    return ApiFactory().apiSubjectDeleteIdDelete({ id });
}

export const downloadDocFile = (subjectId: string) => {
    return ApiFactory().apiSubjectGenerateSubjectFileSubjectIdGet({ subjectId });
}

export const downloadPdfFile = (subjectId: string) => {
    return ApiFactory().apiSubjectGenerateSubjectPdfFileSubjectIdGet({ subjectId });
}

export const assignLaboratories = (assignLaboratories: AssignLaboratoresDTO) => {
    return ApiFactory().apiSubjectAssignLaboratoriesPost({ assignLaboratoresDTO: assignLaboratories });
}
