/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DisciplineDataContentDTO
 */
export interface DisciplineDataContentDTO {
    /**
     * 
     * @type {string}
     * @memberof DisciplineDataContentDTO
     */
    disciplineName?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DisciplineDataContentDTO
     */
    laboratoryWorks?: Array<string> | null;
}

export function DisciplineDataContentDTOFromJSON(json: any): DisciplineDataContentDTO {
    return DisciplineDataContentDTOFromJSONTyped(json, false);
}

export function DisciplineDataContentDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisciplineDataContentDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disciplineName': !exists(json, 'disciplineName') ? undefined : json['disciplineName'],
        'laboratoryWorks': !exists(json, 'laboratoryWorks') ? undefined : json['laboratoryWorks'],
    };
}

export function DisciplineDataContentDTOToJSON(value?: DisciplineDataContentDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disciplineName': value.disciplineName,
        'laboratoryWorks': value.laboratoryWorks,
    };
}

