import { EquipmentRoomDataDTO, LaboratoriesDataApi, LaboratoryRoomDataDTO, PlanLaboratoryDTO, RoomDataDTO } from "../Api"; // Import the generated API
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new LaboratoriesDataApi(getAuthConfiguration());

export const getRoomData = (studyProgramId: string, schoolYear: number): Promise<RoomDataDTO[]> => {
    return ApiFactory().apiLaboratoriesDataContentStudyProgramIdSchoolYearGet({studyProgramId, schoolYear});
  };
  
  export const getRoomEquipmentData = (studyProgramId: string, schoolYear: number): Promise<EquipmentRoomDataDTO[]> => {
    return ApiFactory().apiLaboratoriesDataEquipmentStudyProgramIdSchoolYearGet({studyProgramId, schoolYear});
  };
  
  export const getLaboratoryCapacity = (studyProgramId: string, schoolYear: number): Promise<LaboratoryRoomDataDTO[]> => {
    return ApiFactory().apiLaboratoriesDataCapacityStudyProgramIdSchoolYearGet({studyProgramId, schoolYear});
  };
  
  export const getLaboratories = (studyProgramId: string, schoolYear: number): Promise<PlanLaboratoryDTO[]> => {
    return ApiFactory().apiLaboratoriesDataLaboratoriesStudyProgramIdSchoolYearGet({studyProgramId, schoolYear});
  };