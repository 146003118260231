import { useState } from "react";
import styles from "./Subjects.module.scss";
import { Button, Form, Modal, Select, Spin, Table, Tag, Tooltip, Typography } from "antd";
import Layout from "../../Containers/Layout";
import Search from 'antd/lib/input/Search';
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiOutlined, BarChartOutlined, DesktopOutlined, PlusOutlined, ProfileOutlined, UserOutlined } from "@ant-design/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { theme } from "../../theme";
import { useIsAdmin } from "../../utils/utilFunctions";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import { assignLaboratories, deleteSubject, downloadDocFile, downloadPdfFile, getShortSubjects } from "../../Requests/subject-requests";
import { SubjectFileDTO, SemesterTypeEnum, StudyYearEnum, StudyCycleEnum, IndicatorTypeEnum, StudyPlanDTO, StudyPlanStatusEnum, Subject, SubjectDTO } from "../../Api";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { getSchoolYear, getStudyYearOptions, planOptions, semesterOptions } from "./helpers";
import { getLaboratoriesByUserFaculty } from "../../Requests/laboratory-requests";
import useDebounce from "../../Hooks/debounce";
import { getAllDepartmentsForFaculty } from "../../Requests/departement-requests";
import { getAllStudyPlans, updateStudyPlanDepartment } from "../../Requests/study-plan-requests";
import { downloadFile, MimeType } from "../../utils/downloadUtils";

const { Option } = Select;

const Subjects = () => {
    const { t } = useTranslation();
    const [searchTermNameRO, setSearchTermNameRO] = useState('');
    const [isCadru, setIsCadru] = useState(false);
    const [facultyId, setFacultyId] = useState<string | undefined>(undefined);
    const [selectedPlan, setSelectedPlan] = useState<StudyPlanDTO | undefined>(undefined);

    const [schoolYear, setSchoolYear] = useState<number | undefined>(getSchoolYear(new Date()));
    const [planYear, setPlanYear] = useState<number | undefined>(undefined);
    const [semester, setSemester] = useState<SemesterTypeEnum>(SemesterTypeEnum.S1);
    const [studyYear, setStudyYear] = useState<StudyYearEnum>(StudyYearEnum.L1);
    const [searchTermName, setLabSearchTerm] = useState('');
	const debouncedLabSearch = useDebounce(searchTermName, 1000);

    const history = useHistory();
    const isAdmin = useIsAdmin();
    const { programId, studyCycle } = useParams<{ programId: string, studyCycle: StudyCycleEnum }>();

    const [form] = Form.useForm<{ assignedLabs: string[] }>();

    const [modalPropsDelete, setModalPropsDelete] = useState({
        isModalVisible: false,
        title: '',
        id: '',
    });

    const [modalProps, setModalProps] = useState<{ isModalVisible: boolean, title: string, record: SubjectFileDTO }>({
        isModalVisible: false,
        title: '',
        record: {} as SubjectFileDTO
    });

    const handleShowDelete = (id: string) => {
        setModalPropsDelete(modalProps => ({ ...modalProps, isModalVisible: true, id }));
    }

    const handleCloseDelete = () => {
        setModalPropsDelete(modalProps => ({ ...modalProps, isModalVisible: false }));
    }

    const handleDocDownload = (record: SubjectDTO) => {
        downloadFile(
            downloadDocFile(record.id!), "Fișă Disciplină " + record.nameRO, MimeType.docx 
        ).catch((err) => failed(err, 'Descărcarea nu a fost efectuată!'));
    }

    const handlePdfDownload = (record: SubjectDTO) => {
        downloadFile(
            downloadPdfFile(record.id!), "Fișă Disciplină " + record.nameRO 
        ).catch((err) => failed(err, 'Descărcarea nu a fost efectuată!'));
    }

    const handleOkDelete = () => {
        setModalPropsDelete(modalProps => ({ ...modalProps, isModalVisible: false }));
        deleteSubject(modalPropsDelete.id)
            .then(() => {
                success('Ștergerea a fost efectuată cu succes!');
                invalidateQuery();
            })
            .catch((err) => failed(err, 'Ștergerea nu a fost efectuată!'));
    }

    const handleUpdateLabs = () => {
        setModalProps(modalProps => ({ ...modalProps, isModalVisible: false }));
        setLabSearchTerm('');
        let labs = form.getFieldValue('assignedLabs');
        console.warn(labs);
        assignLaboratories({ subjectId: modalProps.record.id!, laboratoryIds: labs })
        .then(() => {
            success('Laboratoarele au fost actualizate cu succes!');
            invalidateQuery();
        })
        .catch((err) => failed(err, 'Laboratoarele nu au putut fi actualizate!'));
    }

    const success = (message: string) => {
        openNotification(
            'Succes',
            message,
            NOTIFICATION_TYPES.SUCCESS
        );
    };

    const failed = (_err: any, message: string) => {
        openNotification(
            'Eroare',
            message,
            NOTIFICATION_TYPES.ERROR
        );
    };

    const queryClient = useQueryClient();

    const invalidateQuery = () => { 
        queryClient.invalidateQueries('getSubjects'); 
        queryClient.invalidateQueries('getAllStudyPlans')
     };

    const openFetchError = (_error: any) => {
        openNotification(
            'Eroare!',
            'Datele nu au putut fi preluate de la server!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data, isLoading } = useQuery(['getSubjects', searchTermNameRO, schoolYear, semester, studyYear, planYear],
        () => {
            const response = getShortSubjects(searchTermNameRO, schoolYear, planYear, semester, studyYear, programId , 1, 500);
            return response;
        },
        {
            onError: openFetchError,
        }
    );

    const { data: departments } = useQuery(['getFacultyDepartments', facultyId],
        () => {
            if (facultyId) {
                const response = getAllDepartmentsForFaculty(facultyId);
                return response;
            }
            return [];
        },
        {
            onError: openFetchError,
        }
      );

      const { data: plans } = useQuery(['getAllStudyPlans'],
        () => {
            const response = getAllStudyPlans(programId, 1, 10);
            
            return response;
        },
        {
            onError: openFetchError,
        }
      );
    

    const handleClose = () => {
        setLabSearchTerm('');
        setModalProps(modalProps => ({...modalProps, isModalVisible: false}));
    }



    const FilterByNameROInput = (
        <Search placeholder={t('subject.nameRO')} allowClear onSearch={setSearchTermNameRO} style={{ width: '100%' }} />
    );

    const handleShowDetails = (record: SubjectFileDTO) => {
        form.setFieldsValue({ assignedLabs: record.laboratories?.map(lab => lab.id) });
        setLabSearchTerm('');
        setModalProps(modalProps => ({...modalProps, isModalVisible: true, title: "Laboratoare", record}));
    }

    let columns: any = [
        {
            title: 'Nr. Crt.',
            dataIndex: 'index',
            width: '50px',
            key: 'index',
            render: (text: any, record: any, index: number) => index + 1,
        },
        {
            title: 'Codul Disciplinei',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Denumirea Disciplinei',
            dataIndex: 'nameRO',
            key: 'nameRO',
        },
        {
            title: 'Categorie formativă',
            dataIndex: 'formativeCategory',
            key: 'formativeCategory',
            render: (text: any, record: any, index: number) => {
                return record.formativeCategory === IndicatorTypeEnum.Fundamental ? <Tag color='magenta'  key={'Fundamentală'}>Fundamentală</Tag>
                    : record.formativeCategory === IndicatorTypeEnum.Domain ? <Tag color='volcano'  key={'De domeniu'}>De domeniu</Tag>
                    : record.formativeCategory === IndicatorTypeEnum.Complementary ? <Tag color='gold'  key={'Complementară'}>Complementară</Tag>
                    : record.formativeCategory === IndicatorTypeEnum.Specialty ? <Tag color='purple'  key={'De specialitate'}>De specialitate</Tag>
                    : 'N/A'
            }
        },
        {
            title: 'Tip disciplină',
            dataIndex: 'subjectCategory',
            key: 'subjectCategory',
            render: (text: any, record: any, index: number) => {
                return record.subjectCategory === IndicatorTypeEnum.Mandatory ? <Tag color='gold'  key={'Obligatorie'}>Obligatorie</Tag>
                    : record.subjectCategory === IndicatorTypeEnum.Optional ? <Tag color='green'  key={'Opțională'}>Opțională</Tag>
                    : record.subjectCategory === IndicatorTypeEnum.Facultative ? <Tag color='orange'  key={'Facultativă'}>Facultativă</Tag>
                    : 'N/A'
            }
        },
        {
            title: 'Nr. ECTS',
            dataIndex: 'credits',
            key: 'credits',
        },
        {
            title: 'Ore/Săptămână',
            key: 'weeklyHours',
            children: [
                {
                    title: 'Curs',
                    dataIndex: 'courseWeeklyHours',
                    key: 'courseWeeklyHours',
                },
                {
                    title: 'Laborator',
                    dataIndex: 'labWeeklyHours',
                    key: 'labWeeklyHours',
                },
                {
                    title: 'Seminar',
                    dataIndex: 'seminarWeeklyHours',
                    key: 'seminarWeeklyHours',
                },
                {
                    title: 'Proiect',
                    dataIndex: 'projectWeeklyHours',
                    key: 'projectWeeklyHours',
                },
            ],
        },
        {
            title: 'Total Ore',
            key: 'totalHours',
            children: [
                {
                    title: 'Activități Asistate',
                    dataIndex: 'tutoringHours',
                    key: 'tutoringHours',
                },
                {
                    title: 'Studii Independente',
                    dataIndex: 'selfStudyHours',
                    key: 'selfStudyHours',
                },
            ],
        },
        {
            title: 'Forma de Evaluare',
            dataIndex: 'evaluationType',
            key: 'evaluationType',
        }
    ];

    if (isCadru && selectedPlan?.status !== StudyPlanStatusEnum.Approved) { 
        columns.push(
            {
                dataIndex: 'actiuni',
                title: 'Acțiuni',
                width: '100px',
                key: 'actions',
                render: (text: string, record: any) => (
                    <div>
                        <Link to={{ pathname: '/' + studyCycle + '/editare-disciplina-short/' + programId + "/" + record.id, state:  selectedPlan}}>
                            <Tooltip title='Editare'>
                                <FontAwesomeIcon
                                    icon={solid('edit')}
                                    style={{
                                        cursor: 'pointer',
                                        height: '1rem',
                                        padding: '6px 0px',
                                    }}
                                />
                            </Tooltip>
                        </Link>
                        <Tooltip title='Ștergere'>
                            <FontAwesomeIcon
                                icon={solid('trash')}
                                style={{
                                    marginLeft: '20px',
                                    cursor: 'pointer',
                                    height: '1rem',
                                    padding: '6px 0px',
                                }}
                                onClick={() => handleShowDelete(record.id)}
                            />
                        </Tooltip>
                        
                    </div>
                )
            }
        );
    }

    if (!isCadru) { 
        columns.push( {
            title: 'Laborator',
            key: 'laboratory',
            render: (text: string, record: any) =>
                record.labWeeklyHours > 0 ? (
                    <Tooltip title='Laborator'>
                        <FontAwesomeIcon
                            icon={solid('display')}
                            style={{
                                marginLeft: '20px',
                                cursor: 'pointer',
                                height: '1rem',
                                padding: '6px 0px',
                            }}
                            onClick={() => handleShowDetails(record)}
                        />
                    </Tooltip>
                ) : null,
        });
        columns.push(
        {
            title: 'Fișă diciplină',
            key: 'file',
            width: '200px',
            render: (text: string, record: any) =>
                <div>
                    <Link to={{ pathname: '/' + studyCycle + '/editare-disciplina/' + programId + "/" + record.id}}>
                        <Tooltip title='Editare'>
                            <FontAwesomeIcon
                                icon={solid('edit')}
                                style={{
                                    cursor: 'pointer',
                                    height: '1rem',
                                    padding: '6px 0px',
                                }}
                            />
                        </Tooltip>
                    </Link>
                    
                    <Tooltip title='Descarcare'>
                            <FontAwesomeIcon
                                icon={solid('download')}
                                style={{
                                    marginLeft: '20px',
                                    cursor: 'pointer',
                                    height: '1rem',
                                    padding: '6px 0px',
                                }}
                                onClick={() => handleDocDownload(record)}
                            />
                        </Tooltip>
                        <Tooltip title='Descarcare'>
                            <FontAwesomeIcon
                                icon={solid('file-pdf')}
                                style={{
                                    marginLeft: '20px',
                                    cursor: 'pointer',
                                    height: '1rem',
                                    padding: '6px 0px',
                                }}
                                onClick={() => handlePdfDownload(record)}
                            />
                        </Tooltip>
                </div>,
        });
    }


    const handleSemesterChange = (value: SemesterTypeEnum) => {
        setSemester(value);
    }

    const handleStudyYearChange = (value: StudyYearEnum) => {
        setStudyYear(value);
    }

    const handlePlanChange = (value: string) => {
        console.warn(value);
        
        if (value.includes('cadru')) {
            setPlanYear(parseInt(value.split(' ')[1]));
            setSchoolYear(undefined);
            setIsCadru(true);
            setFacultyId(plans?.data?.find(plan => plan.planYear === parseInt(value.split(' ')[1]))?.facultyId);
            setSelectedPlan(plans?.data?.find(plan => plan.planYear === parseInt(value.split(' ')[1])));
        } else {
            setSchoolYear(parseInt(value));
            setPlanYear(undefined);
            setIsCadru(false);
        }

    }

    const handleDepartmentChange = (value: string) => {
        console.warn(value);
        console.warn(selectedPlan);
        if (!!selectedPlan) {
            updateStudyPlanDepartment(selectedPlan.id!, value)
            .then(() => {
                success('Departamentul a fost actualizat cu succes!');
                invalidateQuery();
            })
            .catch((err) => failed(err, 'Departamentul nu a putut fi actualizat!'));
        }
    }

    const { data: labs, isLoading: isLoadingLabSearch } = useQuery(['getLaboratories', debouncedLabSearch],
        () => {
            const response = getLaboratoriesByUserFaculty(undefined, undefined, debouncedLabSearch, 1, 20);
            
            return response;
        },
        {
            onError: openFetchError,
        }
    );


    const studyYearOptions = getStudyYearOptions(studyCycle);

    return (
        <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
            <Layout>
                <div style={{ marginBottom: '20px', display: 'flex', gap: '20px' }}>
                    <Select
                        placeholder="Anul de Studiu"
                        style={{ width: '200px' }}
                        onChange={handleStudyYearChange}
                        defaultValue={StudyYearEnum.L1}
                    >
                        {studyYearOptions}
                    </Select>
                    <Select
                        placeholder="Semestrul"
                        style={{ width: '200px' }}
                        onChange={handleSemesterChange}
                        defaultValue={SemesterTypeEnum.S1}
                    >
                        {semesterOptions}
                    </Select>
                    {/*  */}
                    <Select
                        placeholder="Plan curent/cadru"
                        style={{ width: '200px' }}
                        onChange={handlePlanChange}
                        defaultValue={getSchoolYear(new Date()).toString()}
                        defaultActiveFirstOption
                    >
                        {planOptions(plans?.data || [])}
                    </Select>
                    {isCadru && selectedPlan?.status !== StudyPlanStatusEnum.Approved ? <div>
                        <Tooltip title="Adaugă Disciplină">
                            <Link to={{ pathname: '/' + studyCycle + '/adaugare-disciplina-short/' + programId}}>
                                <Button type="primary" icon={<PlusOutlined />}>
                                    Adaugă Disciplină
                                </Button>
                            </Link>
                        </Tooltip>
                    </div>
                    : null}
                    {isCadru && selectedPlan?.status !== StudyPlanStatusEnum.Approved ? <Select
                        style={{ width: '600px' }}
                        onChange={handleDepartmentChange}
                        value={plans?.data?.find(plan => plan.planYear === planYear)?.departmentId}
                        defaultActiveFirstOption
                    >
                        {departments?.map(department => (
                            <Option key={department.id} value={department.id}>
                                {department.departmentNameRo}
                            </Option>
                        ))}
                    </Select>
                    : null}
                </div>
                {isCadru && selectedPlan?.status !== StudyPlanStatusEnum.Approved 
                ? <div style={{ marginBottom: '20px', display: 'flex', gap: '20px' }}>
                    <Tooltip title="Indicatori">
                        <Link to={{ pathname: '/statusIndicatori/' + programId + '/' + planYear}}>
                            <Button type="primary" icon={<BarChartOutlined />} style={{ width: '200px' }}>
                                Indicatori
                            </Button>
                        </Link>
                    </Tooltip>
                    </div>
                : null}
                {!isCadru ? <div style={{ marginBottom: '20px', display: 'flex', gap: '20px' }}>
                    
                    <Tooltip title="Laboratoare" >
                        {/* <Link to={{ pathname: '/' + studyCycle + '/adaugare-disciplina-short/' + programId}}> */}
                        <Link to={{ pathname: '/laboratoarePlan/' + programId + '/' + schoolYear}}>
                            <Button type="primary" icon={<DesktopOutlined />} style={{ width: '200px' }}>
                                Laboratoare
                            </Button>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Continut Lab" >
                        <Link to={{ pathname: '/laboratoareContent/' + programId + '/' + schoolYear}}>
                            <Button type="primary" icon={<ProfileOutlined />} style={{ width: '200px' }}>
                                Continut Lab
                            </Button>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Echipament Lab" >
                        <Link to={{ pathname: '/laboratoareEchipament/' + programId + '/' + schoolYear}}>
                            <Button type="primary" icon={<ApiOutlined />} style={{ width: '200px' }}>
                                Echipament Lab
                            </Button>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Capacitate Lab" >
                        <Link to={{ pathname: '/laboratoareCapacitate/' + programId + '/' + schoolYear}}>
                            <Button type="primary" icon={<UserOutlined />} style={{ width: '200px' }}>
                                Capacitate Lab
                            </Button>
                        </Link>
                    </Tooltip>
                        
                </div>
                : null}
                    
                <Modal
                    open={modalPropsDelete.isModalVisible}
                    onOk={handleOkDelete}
                    onCancel={handleCloseDelete}
                    title="Ștergere"
                    width={"400px"}
                    okText={t('account.confirm')}
                    cancelText={t('account.cancel')}
                    cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                    okButtonProps={{ style: { background: theme.secondColor, border: theme.secondColor, borderRadius: "10px" } }}
                >
                    <div>
                        <Typography>Sunteți sigur că doriți ștergerea disciplinei?</Typography>
                    </div>
                </Modal>

                <Modal
                    open={modalProps.isModalVisible}
                    onOk={handleUpdateLabs}
                    onCancel={handleClose}
                    title={modalProps.title}
                    width={"500px"}
                    okText={t('account.confirm')}
                    cancelText={t('account.cancel')}
                    cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                    okButtonProps={{ style: { background: theme.secondColor, border: theme.secondColor, borderRadius: "10px" } }}
                    >
                        <div>
                            <Form form={form} layout="vertical">
                                <Form.Item name="assignedLabs">
                                    <Select
                                        className={styles.antSelect}
                                        mode="multiple"
                                        maxTagCount={20}
                                        allowClear
                                        showSearch
                                        style={{ width: "100%", textAlign: "left" }}
                                        placeholder={"Căutare laborator"}
                                        filterOption={false}
                                        onSearch={setLabSearchTerm}
                                        notFoundContent={
                                            isLoadingLabSearch ? <Spin size="small" /> : null
                                        }
                                    >
                                        {[
                                            ...(modalProps?.record?.laboratories || []),
                                            ...(labs?.data?.filter(lab => !(modalProps?.record?.laboratories || []).some(recordLab => recordLab.id === lab.id)) || [])
                                        ]?.map((lab) => (
                                                <Option key={lab.id} value={lab.id}>
                                                    {lab.number}
                                                </Option>
                                            ))
                                            }
                                    </Select>
                                </Form.Item> 
                            </Form>
                        </div>
                </Modal>
                <Table
                    className={styles.subjectsTable}
                    dataSource={data?.data || []}
                    bordered
                    pagination={false}
                    locale={{
                        emptyText: (
                            <span>
                                <h3>Nu există discipline adăugate! Apăsați butonul de mai jos pentru a adăuga o disciplină nouă!</h3>
                                <Tooltip title="Adaugă Disciplină">
                                    <Link to={{ pathname: '/' + studyCycle + '/adaugare-disciplina-short/' + programId}}>
                                        <Button type="primary" icon={<PlusOutlined />}>
                                            Adaugă Disciplină
                                        </Button>
                                    </Link>
                                </Tooltip>
                            </span>
                          )
                    }}
                    columns={columns}
                    rowKey={record => record.id!}
                    loading={isLoading}
                />
            </Layout>
        </div>
    );
}

export default Subjects;