/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlanLaboratoryDTO
 */
export interface PlanLaboratoryDTO {
    /**
     * 
     * @type {string}
     * @memberof PlanLaboratoryDTO
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlanLaboratoryDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlanLaboratoryDTO
     */
    category?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PlanLaboratoryDTO
     */
    area?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanLaboratoryDTO
     */
    capacity?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlanLaboratoryDTO
     */
    disciplines?: Array<string> | null;
}

export function PlanLaboratoryDTOFromJSON(json: any): PlanLaboratoryDTO {
    return PlanLaboratoryDTOFromJSONTyped(json, false);
}

export function PlanLaboratoryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanLaboratoryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'disciplines': !exists(json, 'disciplines') ? undefined : json['disciplines'],
    };
}

export function PlanLaboratoryDTOToJSON(value?: PlanLaboratoryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'name': value.name,
        'category': value.category,
        'area': value.area,
        'capacity': value.capacity,
        'disciplines': value.disciplines,
    };
}

