/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddSubjectDTO,
    AddSubjectDTOFromJSON,
    AddSubjectDTOToJSON,
    AddSubjectFileDTO,
    AddSubjectFileDTOFromJSON,
    AddSubjectFileDTOToJSON,
    AssignLaboratoresDTO,
    AssignLaboratoresDTOFromJSON,
    AssignLaboratoresDTOToJSON,
    SemesterTypeEnum,
    SemesterTypeEnumFromJSON,
    SemesterTypeEnumToJSON,
    StudyYearEnum,
    StudyYearEnumFromJSON,
    StudyYearEnumToJSON,
    SubjectDTO,
    SubjectDTOFromJSON,
    SubjectDTOToJSON,
    SubjectDTOPagedResponse,
    SubjectDTOPagedResponseFromJSON,
    SubjectDTOPagedResponseToJSON,
    SubjectFileDTO,
    SubjectFileDTOFromJSON,
    SubjectFileDTOToJSON,
    SubjectFileDTOPagedResponse,
    SubjectFileDTOPagedResponseFromJSON,
    SubjectFileDTOPagedResponseToJSON,
} from '../models';

export interface ApiSubjectAddPrimarySubjectPostRequest {
    addSubjectDTO?: AddSubjectDTO;
}

export interface ApiSubjectAddSubjectPostRequest {
    addSubjectFileDTO?: AddSubjectFileDTO;
}

export interface ApiSubjectAssignLaboratoriesPostRequest {
    assignLaboratoresDTO?: AssignLaboratoresDTO;
}

export interface ApiSubjectDeleteIdDeleteRequest {
    id: string;
}

export interface ApiSubjectGenerateSubjectFileSubjectIdGetRequest {
    subjectId: string;
}

export interface ApiSubjectGenerateSubjectPdfFileSubjectIdGetRequest {
    subjectId: string;
}

export interface ApiSubjectGetAllSubjectsGetRequest {
    namePattern?: string;
    schoolYear?: number;
    planYear?: number;
    semester?: SemesterTypeEnum;
    studyYear?: StudyYearEnum;
    studyProgramId?: string;
    page?: number;
    pageSize?: number;
}

export interface ApiSubjectGetAllSubjectsPrimDataGetRequest {
    namePattern?: string;
    schoolYear?: number;
    planYear?: number;
    semester?: SemesterTypeEnum;
    studyYear?: StudyYearEnum;
    studyProgramId?: string;
    page?: number;
    pageSize?: number;
}

export interface ApiSubjectGetSubjectByIdIdGetRequest {
    id: string;
}

export interface ApiSubjectGetSubjectPrimDataByIdIdGetRequest {
    id: string;
}

export interface ApiSubjectUpdatePrimarySubjectIdPutRequest {
    id: string;
    addSubjectDTO?: AddSubjectDTO;
}

export interface ApiSubjectUpdateSubjectIdPutRequest {
    id: string;
    addSubjectFileDTO?: AddSubjectFileDTO;
}

/**
 * 
 */
export class SubjectApi extends runtime.BaseAPI {

    /**
     */
    async apiSubjectAddPrimarySubjectPostRaw(requestParameters: ApiSubjectAddPrimarySubjectPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/AddPrimarySubject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddSubjectDTOToJSON(requestParameters.addSubjectDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSubjectAddPrimarySubjectPost(requestParameters: ApiSubjectAddPrimarySubjectPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiSubjectAddPrimarySubjectPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiSubjectAddSubjectPostRaw(requestParameters: ApiSubjectAddSubjectPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/AddSubject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddSubjectFileDTOToJSON(requestParameters.addSubjectFileDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSubjectAddSubjectPost(requestParameters: ApiSubjectAddSubjectPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiSubjectAddSubjectPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiSubjectAssignLaboratoriesPostRaw(requestParameters: ApiSubjectAssignLaboratoriesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/AssignLaboratories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignLaboratoresDTOToJSON(requestParameters.assignLaboratoresDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSubjectAssignLaboratoriesPost(requestParameters: ApiSubjectAssignLaboratoriesPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiSubjectAssignLaboratoriesPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiSubjectDeleteIdDeleteRaw(requestParameters: ApiSubjectDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSubjectDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSubjectDeleteIdDelete(requestParameters: ApiSubjectDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiSubjectDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiSubjectGenerateSubjectFileSubjectIdGetRaw(requestParameters: ApiSubjectGenerateSubjectFileSubjectIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.subjectId === null || requestParameters.subjectId === undefined) {
            throw new runtime.RequiredError('subjectId','Required parameter requestParameters.subjectId was null or undefined when calling apiSubjectGenerateSubjectFileSubjectIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/GenerateSubjectFile/{subjectId}`.replace(`{${"subjectId"}}`, encodeURIComponent(String(requestParameters.subjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiSubjectGenerateSubjectFileSubjectIdGet(requestParameters: ApiSubjectGenerateSubjectFileSubjectIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiSubjectGenerateSubjectFileSubjectIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSubjectGenerateSubjectPdfFileSubjectIdGetRaw(requestParameters: ApiSubjectGenerateSubjectPdfFileSubjectIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.subjectId === null || requestParameters.subjectId === undefined) {
            throw new runtime.RequiredError('subjectId','Required parameter requestParameters.subjectId was null or undefined when calling apiSubjectGenerateSubjectPdfFileSubjectIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/GenerateSubjectPdfFile/{subjectId}`.replace(`{${"subjectId"}}`, encodeURIComponent(String(requestParameters.subjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiSubjectGenerateSubjectPdfFileSubjectIdGet(requestParameters: ApiSubjectGenerateSubjectPdfFileSubjectIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiSubjectGenerateSubjectPdfFileSubjectIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSubjectGetAllSubjectsGetRaw(requestParameters: ApiSubjectGetAllSubjectsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubjectFileDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.schoolYear !== undefined) {
            queryParameters['SchoolYear'] = requestParameters.schoolYear;
        }

        if (requestParameters.planYear !== undefined) {
            queryParameters['PlanYear'] = requestParameters.planYear;
        }

        if (requestParameters.semester !== undefined) {
            queryParameters['Semester'] = requestParameters.semester;
        }

        if (requestParameters.studyYear !== undefined) {
            queryParameters['StudyYear'] = requestParameters.studyYear;
        }

        if (requestParameters.studyProgramId !== undefined) {
            queryParameters['StudyProgramId'] = requestParameters.studyProgramId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/GetAllSubjects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectFileDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSubjectGetAllSubjectsGet(requestParameters: ApiSubjectGetAllSubjectsGetRequest = {}, initOverrides?: RequestInit): Promise<SubjectFileDTOPagedResponse> {
        const response = await this.apiSubjectGetAllSubjectsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSubjectGetAllSubjectsPrimDataGetRaw(requestParameters: ApiSubjectGetAllSubjectsPrimDataGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubjectDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.schoolYear !== undefined) {
            queryParameters['SchoolYear'] = requestParameters.schoolYear;
        }

        if (requestParameters.planYear !== undefined) {
            queryParameters['PlanYear'] = requestParameters.planYear;
        }

        if (requestParameters.semester !== undefined) {
            queryParameters['Semester'] = requestParameters.semester;
        }

        if (requestParameters.studyYear !== undefined) {
            queryParameters['StudyYear'] = requestParameters.studyYear;
        }

        if (requestParameters.studyProgramId !== undefined) {
            queryParameters['StudyProgramId'] = requestParameters.studyProgramId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/GetAllSubjectsPrimData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSubjectGetAllSubjectsPrimDataGet(requestParameters: ApiSubjectGetAllSubjectsPrimDataGetRequest = {}, initOverrides?: RequestInit): Promise<SubjectDTOPagedResponse> {
        const response = await this.apiSubjectGetAllSubjectsPrimDataGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSubjectGetSubjectByIdIdGetRaw(requestParameters: ApiSubjectGetSubjectByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubjectFileDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSubjectGetSubjectByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/GetSubjectById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectFileDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiSubjectGetSubjectByIdIdGet(requestParameters: ApiSubjectGetSubjectByIdIdGetRequest, initOverrides?: RequestInit): Promise<SubjectFileDTO> {
        const response = await this.apiSubjectGetSubjectByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSubjectGetSubjectPrimDataByIdIdGetRaw(requestParameters: ApiSubjectGetSubjectPrimDataByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubjectDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSubjectGetSubjectPrimDataByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/GetSubjectPrimDataById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiSubjectGetSubjectPrimDataByIdIdGet(requestParameters: ApiSubjectGetSubjectPrimDataByIdIdGetRequest, initOverrides?: RequestInit): Promise<SubjectDTO> {
        const response = await this.apiSubjectGetSubjectPrimDataByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSubjectUpdatePrimarySubjectIdPutRaw(requestParameters: ApiSubjectUpdatePrimarySubjectIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSubjectUpdatePrimarySubjectIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/UpdatePrimarySubject/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddSubjectDTOToJSON(requestParameters.addSubjectDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSubjectUpdatePrimarySubjectIdPut(requestParameters: ApiSubjectUpdatePrimarySubjectIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiSubjectUpdatePrimarySubjectIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiSubjectUpdateSubjectIdPutRaw(requestParameters: ApiSubjectUpdateSubjectIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSubjectUpdateSubjectIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/UpdateSubject/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddSubjectFileDTOToJSON(requestParameters.addSubjectFileDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSubjectUpdateSubjectIdPut(requestParameters: ApiSubjectUpdateSubjectIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiSubjectUpdateSubjectIdPutRaw(requestParameters, initOverrides);
    }

}
